import React from 'react';

export const ValidationError = ({ errorMessage, fieldTouched }) => {
  return (
    <>
      {errorMessage && fieldTouched ? (
        <div
          className="font-weight-bold font-size-sm mt-1"
          style={{
            color: '#F05B7F',
            width: '260px',
          }}
        >
          {errorMessage}
        </div>
      ) : null}
    </>
  );
};
