import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useHistory, useParams } from 'react-router-dom';
import SelectBox from '../../../common/SelectBox';
import { hasOnlyOneDot } from '../../../helpers/removeNonNumberic';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import {
  pocketTopUpByAdmin,
  fetchAllCurrencies,
  pocketWithdrawByAdmin,
  getApprovedSettlementDetails,
} from '../_redux/pocketsCrud';
import {
  addPocketTransactionReasons,
  currencyPaymentChannel,
  OperationType,
  OperationTypeOptions,
  serviceProvidersLabel,
  supportedAdminPaymentChannels,
} from '../../../helpers/constants';
import { validatePocketMinimumMaximumAmount } from '../../../helpers/validateMinimumMaximum';
import { ValidationError } from '../../../common/ValidationError';
import Back from '../../../common/Back';
import { getInvestorPocketBalance } from '../../Investor-profile/_redux';
import handleAlert from '../../../common/handleAlert';
import Alert from '../../../common/Alert';
import Loader from '../../../common/Loader';
import { formatNumber } from '../../../helpers/helper';

export const AddPocketTransaction = () => {
  const history = useHistory();
  const { investorId } = useParams();

  const [currencyDropdownItems, setCurrencyDropdownItems] = useState();
  const [pocketDropdownItems, setPocketDropdownItems] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedCurrencyDetails, setSelectedCurrencyDetails] = useState();
  const [fieldsError, setFieldsError] = useState();
  const [alert, setAlert] = useState();
  const [settlementDetailsList, setSettlementDetailsList] = useState();
  const [settlementDetails, setSettlementDetails] = useState();
  const [
    pocketBalanceIsLessThanMinimumWithdrawableAmount,
    setPocketBalanceIsLessThanMinimumWithdrawableAmount,
  ] = useState(false);

  const fetchCurrenciesAndPockets = async () => {
    try {
      const res = await fetchAllCurrencies();
      const currencies = res.data.data;

      const response = await getInvestorPocketBalance(investorId);
      const availablePockets = response.data;

      const pockets =
        availablePockets?.length &&
        availablePockets
          .map(pocket => {
            const currencyDetails = currencies?.find(
              currency => currency.value === pocket.currency,
            );
            return currencyDetails
              ? {
                  ...currencyDetails,
                  balance: pocket.balance,
                  pocketId: pocket.id,
                }
              : null;
          })
          .filter(Boolean);

      setCurrencyDropdownItems(currencies);
      setPocketDropdownItems(pockets);
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message || 'Something went wrong',
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  const fetchSettlementDetails = async () => {
    try {
      const res = await getApprovedSettlementDetails(investorId);
      if (res.status === 200) {
        const filteredSettlementDetails = res.data.items?.map(account => {
          const isMobileMoney = account.type === 'MOBILE_MONEY';

          const accountName = isMobileMoney
            ? serviceProvidersLabel[account?.serviceProvider]
            : account?.bankName;

          return {
            ...account,
            name: accountName?.length ? accountName : account?.accountName,
            value: account?.id,
            id: account?.id,
          };
        });
        setSettlementDetailsList(filteredSettlementDetails);
        setLoading(false);
      }
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message || 'Something went wrong',
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrenciesAndPockets();
    fetchSettlementDetails();
  }, []);

  const initialValues = {
    amount: '',
    currency: '',
    operationType: '',
    reason: '',
    paymentChannel: '',
    settlementDetails: '',
  };

  const createTransactionSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    currency: Yup.string().required('Currency is required'),
    operationType: Yup.string().required('Operation type is required'),
    reason: Yup.string().required('Reason is required'),
  });

  const handleInputChange = e => {
    const inputValue = e.target.value;
    if (isNaN(convertAmountToNumber(inputValue))) {
      return;
    }
    let value = e.target.value.replace(/,/g, '');

    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const hasOneDot = hasOnlyOneDot(value);
    if (!hasOneDot) return false;

    const errorMessage = validatePocketMinimumMaximumAmount(
      formik.values.operationType,
      value,
      selectedCurrencyDetails,
    );

    errorMessage
      ? setFieldsError({ ...fieldsError, amount: errorMessage })
      : setFieldsError({ ...fieldsError, amount: null });

    formik.setFieldValue('amount', value);
  };

  const handleSelectOption = async (fieldName, selectedOption) => {
    const isCurrency = fieldName === 'currency';
    const isSettlementDetails = fieldName === 'settlementDetails';
    const isOperationType = fieldName === 'operationType';

    const currencyDropdownOptions = isOperationType
      ? pocketDropdownItems
      : currencyDropdownItems;

    setCurrencyDropdownItems(currencyDropdownOptions);

    if (isCurrency || isOperationType)
      setPocketBalanceIsLessThanMinimumWithdrawableAmount(false);

    if (isSettlementDetails) {
      const settlementDetails = settlementDetailsList?.find(
        details => details.id === selectedOption,
      );
      formik.setFieldValue(fieldName, selectedOption);
      setSettlementDetails(settlementDetails);
    } else {
      formik.setFieldValue(fieldName, selectedOption);
    }

    const currencyName = isCurrency
      ? selectedOption
      : selectedCurrencyDetails?.value;

    const selectedCurrency = currencyDropdownOptions?.find(
      currency => currency.value === currencyName,
    );

    selectedCurrency && setSelectedCurrencyDetails(selectedCurrency);

    //If the dropdown is not currency, get currency details from state
    const currencyDetails = selectedCurrency || selectedCurrencyDetails;
    const pocketBalance = currencyDetails?.balance;

    const isWithdrawalOperation =
      selectedOption === OperationType.WITHDRAW ||
      (isCurrency && formik.values.operationType === OperationType.WITHDRAW);

    const isPocketBalanceLessThanMinimumInvestableAmount =
      pocketBalance > 0 &&
      pocketBalance < currencyDetails?.minimumWithdrawableAmount;

    if (isWithdrawalOperation) {
      try {
        const currentCurrency = isCurrency
          ? selectedOption
          : formik.values.currency;

        if (!currentCurrency) return;

        if (isPocketBalanceLessThanMinimumInvestableAmount) {
          setPocketBalanceIsLessThanMinimumWithdrawableAmount(true);
          setFieldsError({
            ...fieldsError,
            amount: `You can only withdraw ${
              currencyDetails?.value
            } ${formatNumber(pocketBalance)}`,
          });
          formik.setFieldValue('amount', formatNumber(pocketBalance));
          return;
        }
      } catch (error) {
        setAlert({
          alertMessage:
            error?.response?.data?.message || 'Something went wrong',
          alertMessageType: 'error',
        });
      }
    }

    const selectedOperationType = isOperationType
      ? selectedOption
      : formik.values.operationType;

    const errorMessage =
      !isPocketBalanceLessThanMinimumInvestableAmount &&
      validatePocketMinimumMaximumAmount(
        selectedOperationType,
        formik.values.amount,
        currencyDetails,
      );

    errorMessage
      ? setFieldsError({ ...fieldsError, amount: errorMessage, currency: null })
      : setFieldsError({ ...fieldsError, amount: null, currency: null });

    return;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createTransactionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const {
        amount,
        operationType,
        currency,
        reason,
        paymentChannel,
        settlementDetails,
      } = values;

      const isWithdraw = operationType === OperationType.WITHDRAW;

      const payload = {
        amount: convertAmountToNumber(amount),
        currency,
        reason,
        paymentOption: paymentChannel,
      };

      if (isWithdraw) {
        if (!settlementDetails || settlementDetails?.length < 1) {
          formik.setFieldError(
            'settlementDetails',
            'Settlement details is required',
          );

          return;
        }

        payload.settlementDetailId = Number(settlementDetails);
        delete payload.paymentOption;
      } else {
        if (!paymentChannel || paymentChannel?.length < 1) {
          formik.setFieldError('paymentChannel', 'Payment channel is required');
          return;
        }
      }

      setSubmitting(true);

      try {
        const response = isWithdraw
          ? await pocketWithdrawByAdmin(payload, investorId)
          : await pocketTopUpByAdmin(payload, investorId);
        handleAlert(response?.data?.message, 'success');
        setSubmitting(false);
        history.push(`/investor-pocket-balance/${investorId}`);
      } catch (error) {
        setAlert({
          alertMessage:
            error?.response?.data?.message || 'Something went wrong',
          alertMessageType: 'error',
        });
        setSubmitting(false);
      }
    },
    onReset: () => {
      return history.push(`/investor-pocket-balance/${investorId}`);
    },
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const disableSubmitButton =
    (fieldsError?.amount &&
      !pocketBalanceIsLessThanMinimumWithdrawableAmount) ||
    fieldsError?.currency ||
    formik.isSubmitting;

  const disableAmountInput =
    (formik.values.operationType === OperationType.WITHDRAW &&
      selectedCurrencyDetails?.balance === 0) ||
    pocketBalanceIsLessThanMinimumWithdrawableAmount;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-5">
            <Back url={`/investor-pocket-balance/${investorId}`} />{' '}
          </div>

          <div id="add-margin-top" className="flex-row-fluid">
            <form className="form" onSubmit={formik.handleSubmit}>
              <div className="card card-custom">
                <div className="py-3 card-header">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label" style={{ color: '#0071CE' }}>
                      Add transaction details below
                    </h3>
                  </div>
                </div>

                {alert?.alertMessage && <Alert alert={alert} />}

                <div
                  className="card-body"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  }}
                >
                  <div className="mt-3 form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                      Amount
                    </label>
                    <div>
                      <input
                        type="amount"
                        className={`form-control ${getInputClasses('amount')}`}
                        name="amount"
                        {...formik.getFieldProps('amount')}
                        placeholder="amount"
                        defaultValue={formik.values.amount}
                        onChange={handleInputChange}
                        style={{
                          border: '0.5px solid #CAE2F6',
                          background: '#F5F9FD',
                          height: '48px',
                          width: '260px',
                        }}
                        autoComplete="off"
                        disabled={disableAmountInput}
                      />

                      <ValidationError
                        fieldTouched={
                          formik.touched.amount ||
                          fieldsError?.amount?.length > 0
                        }
                        errorMessage={
                          fieldsError?.amount || formik.errors.amount
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                      Operation Type
                    </label>

                    <SelectBox
                      options={OperationTypeOptions}
                      name="operationType"
                      setValue={value =>
                        handleSelectOption('operationType', value)
                      }
                      width="260px"
                      defaultValue={formik.values.operationType}
                      fieldTouched={formik.touched.operationType}
                      errorMessage={formik.errors.operationType}
                      dropdownLabel={'Operation Type'}
                    />
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                      Currency
                    </label>

                    {currencyDropdownItems && (
                      <SelectBox
                        options={currencyDropdownItems}
                        name="Currency"
                        setValue={value =>
                          handleSelectOption('currency', value)
                        }
                        defaultValue={formik.values.currency}
                        width="260px"
                        fieldTouched={
                          formik.touched.currency ||
                          fieldsError?.currency?.length > 0
                        }
                        errorMessage={
                          fieldsError?.currency || formik.errors.currency
                        }
                        dropdownLabel={'Currency'}
                      />
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                      Reason
                    </label>
                    <SelectBox
                      options={addPocketTransactionReasons}
                      name="Reason"
                      setValue={value => handleSelectOption('reason', value)}
                      width="260px"
                      defaultValue={formik.values.reason}
                      fieldTouched={formik.touched.reason}
                      errorMessage={formik.errors.reason}
                      dropdownLabel={'Reason'}
                    />
                  </div>

                  {formik.values.operationType !== OperationType.WITHDRAW ? (
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                        Channel
                      </label>

                      <SelectBox
                        options={
                          formik.values.currency
                            ? currencyPaymentChannel[formik.values.currency]
                            : supportedAdminPaymentChannels
                        }
                        name="paymentChannel"
                        setValue={value =>
                          formik.setFieldValue('paymentChannel', value)
                        }
                        width="260px"
                        defaultValue={formik.values.paymentChannel}
                        fieldTouched={formik.touched.paymentChannel}
                        errorMessage={formik.errors.paymentChannel}
                        dropdownLabel={'Payment Channel'}
                      />
                    </div>
                  ) : settlementDetailsList?.length ? (
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                        Settlement Details
                      </label>

                      <SelectBox
                        options={settlementDetailsList}
                        name="settlementDetails"
                        setValue={value =>
                          handleSelectOption('settlementDetails', value)
                        }
                        width="260px"
                        defaultValue={settlementDetails?.value}
                        fieldTouched={formik.touched.settlementDetails}
                        errorMessage={formik.errors.settlementDetails}
                        dropdownLabel={'Settlement Details'}
                      />
                    </div>
                  ) : null}
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                  }}
                >
                  <button
                    onClick={formik.handleReset}
                    className="mr-2 px-6 btn"
                    style={{
                      color: '#0071CE',
                      background: '#CAE2F6',
                      width: '20%',
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      color: '#fff',
                      background: disableSubmitButton
                        ? 'rgb(191, 193, 200)'
                        : '#0071CE',
                      width: '20%',
                    }}
                    disabled={disableSubmitButton}
                  >
                    Create
                    {formik.isSubmitting && (
                      <span className="ml-2 spinner spinner-white" />
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
