import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Alert from '../../../common/Alert';
import SelectBox from '../../../common/SelectBox';
import {
  adminCreateTransactionReason,
  supportedAdminPaymentChannels,
} from '../../../helpers/constants';
import { fetchAllPlansByInvestorId } from '../../plans/_redux/planCrud';
import { fetchAllCurrencies } from '../../pockets/_redux/pocketsCrud';
import {
  adminCreateGoalTransaction,
  adminCreateTransaction,
  getInvestorGoals,
} from '../_redux';
import { validateMinimumMaximum } from '../../../helpers/validateMinimumMaximum';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import {
  hasOnlyOneDot,
  removeNonNumeric,
} from '../../../helpers/removeNonNumberic';

const initialValues = {
  amount: '',
  currency: '',
  planId: '',
  paymentChannel: '',
  manualCreationReason: '',
  goalId: '',
};

function AddTransaction() {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [allCurrencies, setAllCurrencies] = useState('');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [goals, setGoals] = useState([]);
  const [fieldError, setFieldError] = useState({
    amount: null,
  });
  const createTransactionSchema = Yup.object().shape({
    amount: Yup.string().required('Required field'),
    paymentChannel: Yup.string().required('Required field'),
  });

  const fetchCurrencies = async () => {
    try {
      const res = await fetchAllCurrencies();
      if (res.status === 200) {
        setAllCurrencies(res.data.data);
      }
    } catch (error) {}
  };

  const loadInvestorGoals = async id => {
    try {
      const res = await getInvestorGoals(id);

      if (res.status === 200) {
        const data = res.data.data.map(goal => ({
          id: goal.id,
          value: goal.id,
          name: goal.name,
        }));
        setGoals(data);
      }
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };
  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };

  useEffect(() => {
    if (location?.state.data === 'Goal Investment') {
      loadInvestorGoals(params.id);
    }
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema: createTransactionSchema,
    onSubmit: async values => {
      try {
        if (location?.state.data === 'Goal Investment') {
          delete values.planId;
          const { goalId, amount, ...rest } = values;
          // ensure that a goal has been selected, if not throw an error
          if (!goalId) {
            handleAlert('Please select a goal', 'error');
          } else {
            setLoading(true);
            const res = await adminCreateGoalTransaction(goalId, {
              investorId: params.id,
              amount: parseInt(convertAmountToNumber(amount)),
              ...rest,
            });

            if (res.status === 201) {
              handleAlert('Successfully created', 'success');
              history.push(`/investor-transactions/${params.id}`);
            }

            setLoading(false);
          }
        } else {
          delete values.goalId;
          const { planId, amount, ...rest } = values;

          if (!planId) {
            handleAlert('Please select a fund', 'error');
          } else {
            setLoading(true);
            const res = await adminCreateTransaction(planId, {
              investorId: params.id,
              amount: parseInt(convertAmountToNumber(amount)),
              ...rest,
            });

            if (res.status === 201) {
              handleAlert('Successfully created', 'success');
              history.push(`/investor-transactions/${params.id}`);
            }

            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
        handleAlert(error.response.data.message, 'error');
      }
    },
    onReset: () => {
      return history.push(`/investor-transactions/${params.id}`);
    },
  });

  const handleInputChange = e => {
    const inputValue = e.target.value;
    if (isNaN(convertAmountToNumber(inputValue))) {
      return;
    }
    let value = e.target.value.replace(/,/g, '');

    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const hasOneDot = hasOnlyOneDot(value);
    if (!hasOneDot) return false;

    if (location?.state.data === 'Goal Investment') {
      validateGoalPayment({
        formik,
        amount: value,
        currency: formik.values['currency'],
        goalId: formik.values['goalId'],
      });
    } else {
      validatePayment({
        formik,
        amount: value,
        currency: formik.values['currency'],
        planId: formik.values['planId'],
      });
    }

    formik.setFieldValue('amount', value);
  };

  const validatePayment = ({ formik, amount, currency, planId }) => {
    if (amount && currency && planId) {
      const selectedCurrency = allCurrencies.find(
        item => item.value === currency.toString(),
      );
      const plan = plans.find(item => parseInt(item.id) === parseInt(planId));

      const errorExist = validateMinimumMaximum(
        selectedCurrency,
        amount,
        'plan',
        plan,
      );

      if (errorExist !== null || errorExist !== undefined) {
        formik.setFieldError('amount', errorExist);
        formik.setErrors(prevErrors => ({
          ...prevErrors,
          amount: errorExist,
        }));
        setFieldError({
          amount: errorExist,
        });
      } else {
        setFieldError({
          amount: null,
        });
      }
    }
  };

  const validateGoalPayment = ({ formik, amount, currency, goalId }) => {
    if (amount && currency && goalId) {
      const selectedCurrency = allCurrencies.find(
        item => item.value === currency.toString(),
      );
      const goal = goals.find(item => parseInt(item.id) === parseInt(goalId));
      const errorExist = validateMinimumMaximum(
        selectedCurrency,
        amount,
        'GOAL',
        goal,
      );
      if (errorExist !== null || errorExist !== undefined) {
        formik.setFieldError('amount', errorExist);
        formik.setErrors(prevErrors => ({
          ...prevErrors,
          amount: errorExist,
        }));
        setFieldError({
          amount: errorExist,
        });
      } else {
        setFieldError({
          amount: null,
        });
      }
    }
  };

  const getPlans = async () => {
    try {
      const res = await fetchAllPlansByInvestorId(params.id);

      if (res.status === 200) {
        const data = res.data.data.map(plan => ({
          id: plan.id,
          value: plan.id,
          name: `${plan.id}. ${plan.coolName}`,
          minInvestableAmounts: plan.minInvestableAmounts,
        }));
        setPlans(data);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  useEffect(() => {
    getPlans();
    fetchCurrencies();
  }, []);
  return (
    <>
      <div id="add-margin-top" className="flex-row-fluid">
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="card card-custom">
            <div className="py-3 card-header">
              <div className="card-title align-items-start flex-column">
                <h3 className="card-label" style={{ color: '#0071CE' }}>
                  Add transaction details below
                </h3>
              </div>
            </div>
            <div
              className="card-body"
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              {alertOn && <Alert alert={alert} />}
              {location?.state.data === 'Goal Investment' ? (
                <div className="mt-3 form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                    Goal
                  </label>

                  <SelectBox
                    options={goals}
                    name="Goal"
                    setValue={value => {
                      validateGoalPayment({
                        formik,
                        amount: formik.values['amount'],
                        currency: formik.values['currency'],
                        goalId: value,
                      });
                      formik.setFieldValue('goalId', value);
                    }}
                    width="260px"
                  />
                </div>
              ) : (
                <div className="mt-3 form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                    Fund
                  </label>

                  <SelectBox
                    options={plans}
                    name="Fund"
                    setValue={value => {
                      validatePayment({
                        formik,
                        amount: formik.values['amount'],
                        currency: formik.values['currency'],
                        planId: value,
                      });
                      formik.setFieldValue('planId', value);
                    }}
                    width="260px"
                  />
                </div>
              )}
              <div className="mt-3 form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Amount
                </label>
                <div>
                  <input
                    type="amount"
                    className={`form-control ${getInputClasses('amount')}`}
                    name="amount"
                    {...formik.getFieldProps('amount')}
                    placeholder="amount"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    style={{
                      border: '0.5px solid #CAE2F6',
                      background: '#F5F9FD',
                      height: '48px',
                      width: '260px',
                    }}
                    autoComplete="off"
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div
                      className="font-weight-bold font-size-sm d-flex justify-content-center"
                      style={{
                        color: '#F05B7F',
                        width: '260px',
                      }}
                    >
                      {formik.errors.amount}
                    </div>
                  ) : null}

                  {fieldError.amount ? (
                    <div
                      className="font-weight-bold font-size-sm col-md-10 col-lg-10 col-xl-12 ml-5 mt-4"
                      style={{ color: '#F05B7F' }}
                    >
                      {fieldError.amount}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Currency
                </label>

                {allCurrencies && (
                  <SelectBox
                    options={allCurrencies}
                    name="Currency"
                    setValue={value => {
                      setFieldError({
                        amount: null,
                      });
                      if (location?.state.data === 'Goal Investment') {
                        validateGoalPayment({
                          formik,
                          amount: formik.values['amount'],
                          currency: value,
                          goalId: formik.values['goalId'],
                        });
                      } else {
                        validatePayment({
                          formik,
                          amount: formik.values['amount'],
                          currency: value,
                          planId: formik.values['planId'],
                        });
                      }
                      formik.setFieldValue('currency', value);
                    }}
                    defaultValue={formik.values.currency}
                    width="260px"
                  />
                )}
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Channel
                </label>

                <SelectBox
                  options={supportedAdminPaymentChannels}
                  name="Channel"
                  setValue={value =>
                    formik.setFieldValue('paymentChannel', value)
                  }
                  width="260px"
                />
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Reason
                </label>
                <SelectBox
                  options={adminCreateTransactionReason}
                  name="Reason"
                  setValue={value =>
                    formik.setFieldValue('manualCreationReason', value)
                  }
                  width="260px"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '2rem',
              }}
            >
              <button
                onClick={formik.handleReset}
                className="mr-2 px-6 btn"
                style={{
                  color: '#0071CE',
                  background: '#CAE2F6',
                  width: '20%',
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn"
                style={{
                  color: '#fff',
                  background: '#0071CE',
                  width: '20%',
                }}
              >
                Create
                {loading && <span className="ml-2 spinner spinner-white" />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddTransaction;
