import { OperationType, TransactionType } from './constants';
import convertAmountToNumber from './convertAmountToNumber';
import { formatNumber } from './helper';

export const validateMinimumMaximum = (currency, amount, type, plan = null) => {
  if (type === TransactionType.GOAL) {
    if (
      convertAmountToNumber(amount) <
      convertAmountToNumber(currency.minimumInvestableAmount)
    ) {
      const errorMessage = `The minimum amount is ${
        currency?.value
      } ${formatNumber(currency.minimumInvestableAmount)}`;

      return errorMessage;
    } else if (
      convertAmountToNumber(amount) >
      convertAmountToNumber(currency.maximumInvestableAmount)
    ) {
      const errorMessage = `The maximum amount is ${
        currency?.value
      } ${formatNumber(currency.maximumInvestableAmount)}`;

      return errorMessage;
    }
  } else {
    let minimumInvestableAmount = plan.minInvestableAmounts[currency.value];
    if (convertAmountToNumber(amount) < minimumInvestableAmount) {
      const errorMessage = `The minimum amount is ${
        currency?.value
      } ${formatNumber(minimumInvestableAmount)}`;
      return errorMessage;
    } else if (
      convertAmountToNumber(amount) > currency.maximumInvestableAmount
    ) {
      const errorMessage = `The maximum amount is ${
        currency?.value
      } ${formatNumber(currency.maximumInvestableAmount)}`;

      return errorMessage;
    }
  }
};

export const validatePocketMinimumMaximumAmount = (
  operationType,
  amount,
  currency,
) => {
  let errorMessage = null;

  const enteredAmount = convertAmountToNumber(amount);

  const {
    pocketFlatWithdrawalFee,
    maximumPocketTopupAmount,
    minimumPocketTopupAmount,
    minimumWithdrawableAmount,
    value: currencyName,
    balance,
  } = currency || {};

  if (operationType && operationType === OperationType.WITHDRAW) {
    if (balance === 0) {
      errorMessage = 'The pocket has no balance';
      return errorMessage;
    }
  }

  if (operationType && amount && currency) {
    if (operationType === OperationType.DEPOSIT) {
      if (enteredAmount > maximumPocketTopupAmount) {
        errorMessage = `The maximum amount is ${currencyName} ${formatNumber(
          maximumPocketTopupAmount,
        )}`;

        return errorMessage;
      } else if (enteredAmount < minimumPocketTopupAmount) {
        errorMessage = `The minimum amount is ${currencyName} ${formatNumber(
          minimumPocketTopupAmount,
        )}`;

        return errorMessage;
      } else return null;
    } else {
      if (pocketFlatWithdrawalFee >= enteredAmount) {
        errorMessage =
          pocketFlatWithdrawalFee === enteredAmount
            ? 'The withdrawal amount is equal to transaction fee'
            : 'The withdrawal amount is less than transaction fee';

        return errorMessage;
      } else if (enteredAmount < minimumWithdrawableAmount) {
        errorMessage = `The minimum amount is ${currencyName} ${formatNumber(
          minimumWithdrawableAmount,
        )}`;

        return errorMessage;
      } else if (enteredAmount > balance) {
        errorMessage = 'The amount is greater than the pocket balance';
        return errorMessage;
      }
    }
  }
};
