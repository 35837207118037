import React from 'react';
import { ValidationError } from './ValidationError';

function SelectBox({
  name,
  options,
  setValue = null,
  type = null,
  defaultValue = null,
  disabled = false,
  styles,
  originalFormat = false,
  width = '190px',
  errorMessage,
  fieldTouched,
  dropdownLabel,
}) {
  const defaultStyle = {
    border: '1px solid #0071CE',
    color: '#0071CE',
    background: '#FFFFFF',
    maxWidth: width,
    width: width,
    height: '45px',
    borderRadius: '10px',
    padding: '20px, 148px, 20px, 135px',
    fontWeight: 500,
  };
  return (
    <div>
      <select
        className="btn btn-light-primary"
        onChange={e =>
          setValue(
            originalFormat ? e.target.value : e.target.value.toUpperCase(),
            type,
            e.target.options[e.target.selectedIndex].getAttribute('data-id'),
          )
        }
        defaultValue={defaultValue}
        disabled={disabled}
        style={styles ? styles : defaultStyle}
      >
        {dropdownLabel || name ? (
          <option value=""> {dropdownLabel || name}</option>
        ) : null}

        {options.map(option => (
          <option
            key={option.value || option.id}
            data-id={option.value}
            value={option.value || option.name}
          >
            {option.name || option.value}
          </option>
        ))}
      </select>

      <ValidationError
        fieldTouched={fieldTouched}
        errorMessage={errorMessage}
      />
    </div>
  );
}

export default SelectBox;
