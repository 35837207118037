import React, { useEffect, useState } from 'react';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import moment from 'moment';
import BackIcon from '../../../icons/backIcon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { currencyHelper } from '../../../helpers/helper';
import ErrorCard from '../../../common/ErrorCard';
import { AdminPermissions, TransactionType } from '../../../helpers/constants';
import Pagination from '@material-ui/lab/Pagination';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { fetchTransactions } from '../../UnassignedTransactions/_redux/transactionsCrud';
import AddTransactionModal from '../../../common/AddTransactionModal';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';

const InvestorTransactions = props => {
  const { investorId } = props.match.params;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [InvestorTransaction, setInvestorTransaction] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [investmentType, setInvestmentType] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openAddTransactionModal, setOpenAddTransactionModal] = useState(false);
  const history = useHistory();

  const fetchAllTransactions = async () => {
    try {
      setLoading(true);
      const {
        data: { items, meta },
      } = await fetchTransactions(
        investmentType,
        1,
        limit,
        '',
        '',
        '',
        '',
        '',
        [],
        investorId,
      );

      setAllTransactions(items);
      setInvestorTransaction(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSelect = async e => {
    const updateState = (data, transactionType) => {
      setInvestorTransaction(data.items);
      setInvestmentType(transactionType);
      setTotalRecord(data.meta.totalItems);
      setTotalPages(data.meta.totalPages);
    };
    const { value: selectedValue } = e.target;
    let res;
    switch (selectedValue) {
      case 'all':
        res = await fetchTransactions(
          TransactionType.ALL,
          1,
          limit,
          '',
          '',
          '',
          '',
          '',
          [],
          investorId,
        );
        updateState(res.data, '');
        break;
      case 'plans':
        res = await fetchTransactions(
          TransactionType.DIRECT,
          1,
          limit,
          '',
          '',
          '',
          '',
          '',
          [],
          investorId,
        );
        updateState(res.data, TransactionType.DIRECT);
        break;
      case 'goals':
        res = await fetchTransactions(
          TransactionType.GOAL,
          1,
          limit,
          '',
          '',
          '',
          '',
          '',
          [],
          investorId,
        );
        updateState(res.data, TransactionType.GOAL);
        break;
      default:
        setInvestorTransaction(allTransactions);
        setInvestmentType('');
    }
  };

  const handleChangePage = async (event, value) => {
    const {
      data: { items, meta },
    } = await fetchTransactions(
      investmentType,
      value,
      limit,
      '',
      '',
      '',
      '',
      '',
      [],
      investorId,
    );
    setPage(value);
    setTotalRecord(meta.totalItems);
    setInvestorTransaction(items);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  };
  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const {
      data: { items, meta },
    } = await fetchTransactions(
      investmentType,
      1,
      selectedValue,
      '',
      '',
      '',
      '',
      '',
      [],
      investorId,
    );
    setLimit(selectedValue);
    setTotalRecord(meta.totalItems);
    setTotalPages(meta.totalPages);
    setInvestorTransaction(items);
  };

  useEffect(() => {
    fetchAllTransactions();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  const onBackClick = () => {
    return location.state?.prevUrl
      ? history.push(location.state?.prevUrl)
      : history.push('/dashboard');
  };

  const handleCloseAddTransactionModal = () => {
    setOpenAddTransactionModal(false);
  };

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <>
          <div className="cursor-pointer" onClick={() => onBackClick()}>
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </div>
          <div className="card card-custom card-stretch gutter-b mt-2">
            <div className="card-header pt-7">
              <h4>Investor Transactions</h4>

              {grantPermission(AdminPermissions.ADD_TRANSACTION) && (
                <div className="mr-5 ml-5">
                  <button
                    style={{
                      background: '#0071CE',
                      borderRadius: '5px',
                      padding: '0px 20px',
                      border: '1px solid #0071CE',
                      color: '#ffffff',
                      height: '41px',
                      marginBottom: '10px',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setOpenAddTransactionModal(true);
                    }}
                  >
                    Add transaction
                  </button>
                </div>
              )}
            </div>
            <div className="card-body">
              <select
                className="btn btn-light-primary font-weight-bold mb-10"
                onChange={handleSelect}
              >
                <option value="all">Filters</option>
                <option value="all">All transactions</option>
                <option value="plans">Direct Investments</option>
                <option value="goals">Goal Investments</option>
              </select>

              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg  table-vertical-center">
                    <thead>
                      <tr className="text-center text-uppercase">
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Transaction ID</span>
                        </th>
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Investment Type</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Operation Type</span>
                        </th>
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Source</span>
                        </th>
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Date Executed</span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Fund Name</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Fund Cool Name</span>
                        </th>
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Original Amount</span>
                        </th>
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Discount Amount</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Final converted amount
                          </span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Conversion</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Conversion rate</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Ndovu fee</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Ndovu fee percentage
                          </span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">
                            Applied conversion rate
                          </span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Units</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Transaction Status
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {InvestorTransaction &&
                        InvestorTransaction.map(
                          ({
                            id,
                            createdAt,
                            originalAmount,
                            originalCurrency,
                            operationType,
                            transactionStatus,
                            transactionType,
                            planSubscription,
                            paymentRecord,
                            convertedFinalAmount,
                            finalCurrency,
                            conversion,
                            conversionRate,
                            ndovuFee,
                            ndovuFeePercentage,
                            appliedConversionRate,
                            units,
                            triggerSource,
                          }) => (
                            <tr
                              className={
                                operationType === 'DEPOSIT'
                                  ? 'deposit_background-color row-border'
                                  : 'withdraw_background-color row-border'
                              }
                              key={id}
                            >
                              <td className="pl-0 py-8 text-center">
                                <div className="text-hover-primary mb-1 font-size-lg">
                                  <span className="font-weight-bold text-color">
                                    {id}
                                  </span>
                                </div>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {transactionType}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {operationType}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="text-color d-block font-size-sm">
                                  {triggerSource}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {moment(createdAt).format('YYYY-MM-DD')}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {planSubscription.plan.name}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {planSubscription.plan.coolName}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {currencyHelper(
                                    originalAmount,
                                    originalCurrency,
                                  )}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {paymentRecord
                                    ? currencyHelper(
                                        paymentRecord.discountedAmount,
                                        paymentRecord.currency,
                                      )
                                    : '--'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {currencyHelper(
                                    convertedFinalAmount,
                                    finalCurrency,
                                  )}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {conversion}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {conversionRate
                                    ? Number(conversionRate).toFixed(3)
                                    : '---'}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {Number(ndovuFee).toFixed(3)}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {`${Number(ndovuFeePercentage).toFixed(2)}%`}
                                </span>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {appliedConversionRate
                                    ? Number(appliedConversionRate).toFixed(3)
                                    : '---'}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {units ? units.toFixed(4) : '---'}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {transactionStatus}
                                </span>
                              </td>
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>
                </div>
                <AddTransactionModal
                  open={openAddTransactionModal}
                  handleClose={handleCloseAddTransactionModal}
                  setOpen={setOpenAddTransactionModal}
                  id={investorId}
                />
              </div>
              <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                {totalPages && totalPages > 0 ? (
                  <Pagination
                    page={page}
                    count={totalPages}
                    onChange={handleChangePage}
                  />
                ) : (
                  ''
                )}
                {totalRecord && totalRecord > 10 ? (
                  <NumberOfRows
                    handleChangeLimit={handleChangeLimit}
                    totalRecord={totalRecord}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InvestorTransactions;
