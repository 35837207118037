import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import ErrorCard from '../../../common/ErrorCard';
import {
  AdminPermissions,
  BlockStatus,
  PocketTransactionStatusMap,
  settlementDetailTypes,
} from '../../../helpers/constants';
import BackIcon from '../../../icons/backIcon.svg';
import {
  completeBlock,
  completeTransactionFromPaymentBatch,
  fetchPaymentBlockById,
  removePaymentBatch,
  removeTransactionFromPaymentBatch,
} from '../_redux/pocketsCrud';
import { formatNumber } from '../../../helpers/helper';

const investorTableHeaders = [
  {
    id: 1,
    title: 'Transaction ID',
  },
  {
    id: 2,
    title: 'Date Executed',
  },
  {
    id: 3,
    title: 'User Email',
  },

  {
    id: 4,
    title: 'Operation Type',
  },
  {
    id: 5,
    title: 'Action Type',
  },
  {
    id: 6,
    title: 'Payout Amount',
  },
  {
    id: 7,
    title: 'Settlement Details',
  },
  {
    id: 8,
    title: 'Payment Type',
  },
  {
    id: 9,
    title: 'Bank Name',
  },

  {
    id: 10,
    title: 'Partner',
  },
  {
    id: 11,
    title: 'Country',
  },

  {
    id: 12,
    title: 'Transaction Status',
  },
  {
    id: 13,
    title: 'Complete',
  },
  {
    id: 14,
    title: 'Remove',
  },
];
const PaymentBlockTransactions = props => {
  const history = useHistory();
  const { blockId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [paymentBlock, setPaymentBlock] = useState({});

  const [errorMessage, setErrorMessage] = useState(null);

  const [transactionIds, setTransactionIds] = useState([]);
  const { id, createdAt, status, channel, currency } = paymentBlock;
  const getPaymentBlock = async id => {
    try {
      setLoading(true);
      const res = await fetchPaymentBlockById(id);
      if (res.status === 200) {
        setLoading(false);
        setPaymentBlock(res.data);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleDeleteBlockModal = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await removePaymentBatch(id);
        swalWithBootstrapButtons
          .fire('Deleted!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              history.push('/payment-batch');
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleCompleteBlockModal = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure you want to  complete  transactions from this payment batch?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, complete all!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      setLoading(true);
      try {
        const response = await completeBlock(id);
        setLoading(false);
        swalWithBootstrapButtons
          .fire('Completed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              history.push('/payment-batch');
            }
          });
      } catch (error) {
        setLoading(false);
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleRemoveFromPaymentBatch = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure you want to  remove the selected transactions with id ${id}  from this payment batch?`,

      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      const newTransactionIds = [id];
      try {
        const response = await removeTransactionFromPaymentBatch(
          blockId,
          newTransactionIds,
        );
        swalWithBootstrapButtons
          .fire('Removed successfully!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              history.push('/payment-batch');
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleCompleteTransaction = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure you want to  complete the selected transactions with ${id}  from this payment batch?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send them!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      setTransactionIds(transactionIds.concat(id));

      try {
        const response = await completeTransactionFromPaymentBatch(id);
        swalWithBootstrapButtons
          .fire('Completed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getPaymentBlock(blockId);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  useEffect(() => {
    getPaymentBlock(blockId);
  }, []);

  useEffect(() => {
    if (paymentBlock.transactions) {
      for (const transaction of paymentBlock.transactions) {
        !transactionIds.includes(transaction.id) &&
          setTransactionIds(transactionIds.concat(transaction.id));
      }
    }
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/payment-batch'} errorMessage={errorMessage} />
      ) : (
        <>
          <Link to={'/payment-batch'}>
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </Link>
          <div className="mt-2">
            <div
              className="card card-custom gutter-b mb-3 mt-0"
              style={{ height: '100%' }}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center flex-wrap my-15">
                  <div>
                    <p>
                      Batch: {id} <br />
                      Timestamp block creation:{' '}
                      {moment(createdAt).format('YYYY-MM-DD hh:mm:ss')}
                    </p>
                    <p>
                      Status: <span style={{ color: '#0071CE' }}>{status}</span>
                    </p>
                  </div>

                  <section>
                    {grantPermission(
                      AdminPermissions.MANIPULATE_PAYMENT_BATCH,
                    ) ? (
                      <div>
                        <button
                          className="btn btn-light-primary mt-1 mt-lg-0 mt-md-0 mr-2"
                          disabled={status === BlockStatus.COMPLETED}
                          onClick={() => handleCompleteBlockModal(blockId)}
                        >
                          Complete All
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </section>
                </div>

                <table className="table table-responsive table-head-custom  table-head-bg  table-border table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      {investorTableHeaders.map(header => (
                        <th
                          key={header.id}
                          style={{ minWidth: '100px', textAlign: 'center' }}
                          className="pl-7"
                        >
                          <span className="text-dark-75">{header.title}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paymentBlock?.pocketTransactions?.length > 0 &&
                      paymentBlock?.pocketTransactions?.map(transaction => (
                        <tr key={transaction.id}>
                          <td className="pl-0 py-8 text-center">
                            <span className="d-block font-size-sm">
                              {transaction.id}
                            </span>
                          </td>
                          <td className="pl-0 py-8 text-center ">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {moment(transaction.createdAt).format(
                                'YYYY-MM-DD',
                              )}
                            </div>
                          </td>
                          <td className="pl-0 py-8 text-center">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {transaction.userEmail
                                ? transaction.userEmail
                                : '---'}
                            </div>
                          </td>

                          <td className="pl-0 py-8 text-center">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {transaction.operation
                                ? transaction.operation
                                : '---'}
                            </div>
                          </td>
                          <td className="pl-0 py-8 text-center">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {transaction.source ? transaction.source : '---'}
                            </div>
                          </td>
                          <td className="pl-0 py-8 text-center">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {transaction.withdrawnAmount
                                ? formatNumber(transaction.withdrawnAmount)
                                : '---'}
                            </div>
                          </td>

                          <td className="pl-0 py-8 text-center">
                            <span className="d-block font-size-sm">
                              {transaction.settlementDetail
                                ? transaction.settlementDetail.type ===
                                  settlementDetailTypes.BANK
                                  ? transaction.settlementDetail.accountNumber
                                  : transaction.settlementDetail.phoneNumber
                                : '---'}
                            </span>
                          </td>

                          <td className="pl-0 py-8 text-center">
                            <span className="d-block font-size-sm">
                              {transaction.settlementDetail
                                ? transaction.settlementDetail.serviceProvider
                                : '---'}
                            </span>
                          </td>
                          <td className="pl-0 py-8 text-center">
                            <span className="d-block font-size-sm">
                              {transaction.settlementDetail.bankName
                                ? transaction.settlementDetail.bankName
                                : '---'}
                            </span>
                          </td>
                          <td className="pl-0 py-8 text-center">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {transaction.partnerName
                                ? transaction.partnerName
                                : '---'}
                            </div>
                          </td>

                          <td className="pl-0 py-8 text-center">
                            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                              {paymentBlock.country
                                ? paymentBlock.country
                                : '---'}
                            </div>
                          </td>

                          <td className="pr-0 text-center">
                            <span className="d-block font-size-sm">
                              {transaction.status}
                            </span>
                          </td>
                          {grantPermission(
                            AdminPermissions.MANIPULATE_PAYMENT_BATCH,
                          ) ? (
                            <td>
                              {channel !==
                                settlementDetailTypes.AIRTEL_MONEY && (
                                <button
                                  className="btn btn-light-primary font-size-sm"
                                  disabled={
                                    transaction.status ===
                                    PocketTransactionStatusMap.COMPLETED
                                  }
                                  onClick={() =>
                                    handleCompleteTransaction(transaction.id)
                                  }
                                >
                                  Complete
                                </button>
                              )}
                            </td>
                          ) : (
                            '--'
                          )}

                          <td>
                            <button
                              className="btn btn-light-danger font-size-sm"
                              onClick={() =>
                                handleRemoveFromPaymentBatch(transaction.id)
                              }
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div></div>
          </div>
        </>
      )}
    </>
  );
};

export default injectIntl(PaymentBlockTransactions);
