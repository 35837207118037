import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { Alert } from 'react-bootstrap';
import { getInvestorPocketBalance } from '../_redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import BackIcon from '../../../icons/backIcon.svg';
import { formatNumber } from '../../../helpers/helper';
import Swal from 'sweetalert2';
import moment from 'moment';
import CommonButton from '../../../common/CommonBtn';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';

const tableHeaders = ['id', 'name', 'currency', 'balance', 'status', 'created'];

function InvestorPocketBalance() {
  const params = useParams();
  const investorId = params.id;
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [investorPockets, setInvestorPockets] = useState([]);

  const fetchInvestorPockets = async () => {
    try {
      setLoading(true);
      const res = await getInvestorPocketBalance(investorId);
      if (res.status === 200) {
        setInvestorPockets(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };
  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };
  const onBackClick = () => {
    return location.state?.prevUrl
      ? history.push(location.state?.prevUrl)
      : history.push(`/investor-profile/${investorId}`);
  };
  useEffect(() => {
    fetchInvestorPockets();
  }, []);
  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <>
          <div
            className="cursor-pointer"
            style={{
              marginBottom: '1rem',
            }}
            onClick={() => onBackClick()}
          >
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </div>

          <main className="card card-custom card-stretch gutter-b">
            <section
              style={{
                width: '95%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="mt-4"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p>Investor Pockets</p>
                  </div>
                  {grantPermission(AdminPermissions.POCKETS_MANIPULATION) ? (
                    <CommonButton
                      btnText="Add Transaction"
                      className="text-center full-width-small btn-size common-btn"
                      onClick={() =>
                        history.push({
                          pathname: `/investor-pocket/${investorId}/add-transaction`,
                          state: {
                            prevUrl: location.pathname,
                          },
                        })
                      }
                    />
                  ) : null}
                </div>
              </div>
            </section>
            <section className="card-body mt-10 mb-10 pt-0 pb-3">
              <div className="tab-content">
                <div>
                  <table className="table  table-head-custom table-head-bg  table-border table-vertical-center">
                    <thead>
                      <tr className="text-center text-uppercase">
                        {tableHeaders.map((header, index) => (
                          <th
                            key={index}
                            style={{
                              minWidth: '100px',
                              textAlign: 'center',
                              padding: '8px',
                            }}
                            className="pl-7"
                          >
                            <span style={{ color: '#3699FF' }}> {header} </span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {investorPockets &&
                        investorPockets?.map(investorPocket => (
                          <tr key={investorPocket.id}>
                            <td className="pl-0 py-8 text-center">
                              <span className="d-block font-size-sm">
                                {investorPocket.id}
                              </span>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <span className="d-block font-size-sm">
                                {investorPocket.currencyName}
                              </span>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <span className="d-block font-size-sm">
                                {investorPocket.currency}
                              </span>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <span className="d-block font-size-sm">
                                {formatNumber(investorPocket.balance)}
                              </span>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <span className="d-block font-size-sm">
                                {investorPocket.status}
                              </span>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <span className="d-block font-size-sm">
                                {moment(investorPocket.createdAt).format(
                                  'YYYY-MM-DD',
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default InvestorPocketBalance;
