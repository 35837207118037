import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/admin/transactions`;

const FETCH_SINGLE_TRANSACTION = `${REACT_APP_BASE_URL}/v1/admin/transactions/`;
const MOVE_TRANSACTION_TO_RECONCILED = `${REACT_APP_BASE_URL}/v1/admin/transactions/`;
const FETCH_PARTNERS = `${REACT_APP_BASE_URL}/v1/admin/partners`;
const FETCH_INVESTOR_PORTFOLIO = `${REACT_APP_BASE_URL}/v1/admin/investors`;
const FETCH_SUPPORTED_COUNTRIES = `${REACT_APP_BASE_URL}/v1/admin/countries`;

const fetchTransactions = async (
  investmentType,
  page,
  limit,
  search,
  country,
  startDate,
  endDate,
  partnerId,
  operationType,
  investorId,
) => {
  let URL = `${FETCH_TRANSACTIONS}`;
  let queryParams = [
    'investmentType',
    'page',
    'limit',
    'search',
    'country',
    'startDate',
    'endDate',
    'partnerId',
    'operationType',
    'investorId',
  ];

  let functionParams = [
    investmentType,
    page,
    limit,
    search,
    country,
    startDate,
    endDate,
    partnerId,
    operationType,
    investorId,
  ];

  let params = {};

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      params[queryParams[i]] = functionParams[i];
    }
  }

  const res = await axios.get(URL, { params });
  return res;
};

const fetchInvestorPortfolioById = async investorId => {
  let URL = `${FETCH_INVESTOR_PORTFOLIO}/${investorId}/portfolio`;

  const res = await axios.get(URL);
  return res;
};
const fetchSingleTransaction = async id => {
  const res = await axios.get(`${FETCH_SINGLE_TRANSACTION}${id}`);
  return res;
};

const moveToReconciled = async id => {
  const res = await axios.put(`${MOVE_TRANSACTION_TO_RECONCILED}${id}`);
  return res;
};

const moveToArchived = async (id, reason) => {
  const res = await axios.put(
    `${REACT_APP_BASE_URL}/v1/admin/transactions/${id}/reject`,
    {
      reason,
    },
  );
  return res;
};

const fetchPartners = async (page, limit) => {
  const res = await axios.get(`${FETCH_PARTNERS}?page=${page}&limit=${limit}`);
  return res;
};

const fetchAllPartners = async () => {
  const res = await axios.get(`${FETCH_PARTNERS}`);
  return res;
};

const fetchSupportedCountries = async () => {
  const res = await axios.get(`${FETCH_SUPPORTED_COUNTRIES}`);
  return res;
};

export {
  fetchTransactions,
  fetchSingleTransaction,
  moveToReconciled,
  moveToArchived,
  fetchPartners,
  fetchAllPartners,
  fetchInvestorPortfolioById,
  fetchSupportedCountries,
};
