import React, { useEffect, useState } from 'react';

import { UserLevelOptions1, kycStatusValues } from '../../../helpers/constants';

import SVG from 'react-inlinesvg';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import Pagination from '@material-ui/lab/Pagination';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ExportButton } from '../../../common/CommonBtn';
import { exportToExcel } from '../../../utils/exportToExcel';
import SelectBox from '../../../common/SelectBox';
import SingleInvestor from './SingleInvestors';
import InvestorDate from './InvestorDateRange';
import { fetchInvestors, searchInvestors } from '../_redux';
import handleAlert from '../../../common/handleAlert';
import Loader from '../../../common/Loader';

const investortableHeaders = [
  {
    id: 1,
    title: 'Investor ID',
  },

  {
    id: 2,
    title: 'Investor Type',
  },
  {
    id: 3,
    title: 'Entity Name',
  },
  {
    id: 4,
    title: 'Entity  Email',
  },
  {
    id: 5,
    title: 'investor Phone',
  },
  {
    id: 6,
    title: 'User ID',
  },

  {
    id: 8,
    title: 'Date Registered',
  },
  {
    id: 9,
    title: 'Date KYC Submitted',
  },
  {
    id: 10,
    title: 'Date KYC Evaluated',
  },
  {
    id: 11,
    title: 'Subscription plan',
  },
  {
    id: 12,
    title: 'KYC Status',
  },
  {
    id: 13,
    title: 'Partner',
  },
  {
    id: 14,
    title: 'View Investor',
  },
];

const Investor = () => {
  const [kycStatus, setKycStatus] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userLevel, setUserLevel] = useState('');
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingSearchResult, setLoadingSearchResult] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [showWithPendingTransactions, setShowPendingTransactions] = useState(
    false,
  );
  const [dateValues, setDateValues] = useState({
    startDate: '',
    endDate: '',
  });
  const [searchItem, setSearchItem] = useState('');
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };

  const getInvestors = async () => {
    try {
      setLoading(true);
      const res = await fetchInvestors(page, limit);
      if (res.status === 200) {
        setInvestors(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const searchForInvestors = async (
    kyc,
    userLevel,
    showWithPendingTransactions,
    dateFilter,
    dateValues,
  ) => {
    try {
      setPage(1);
      const res = await searchInvestors(
        kyc,
        userLevel,
        showWithPendingTransactions,
        dateFilter,
        dateValues?.startDate,
        dateValues?.endDate,
        searchItem,
        1,
        limit,
      );
      if (res.status === 200) {
        setInvestors(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoadingData(false);
      }
    } catch (error) {
      handleAlert(error.response.data.message, 'error');
      setLoading(false);
    }
  };
  const setKycStatusValue = e => {
    if (e === 'REJECTED') {
      setKycStatus(e);
    } else {
      setKycStatus(e);
    }
  };
  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await searchInvestors(
      kycStatus,
      userLevel,
      showWithPendingTransactions,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      value.replace('+', '%2B'),
      1,
      limit,
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setInvestors(res.data.items);
    }
  };

  const searchForInvestorUsingDateRange = async (
    kycStatus,
    userLevel,
    userStatus,
    dateColumnName,
    dateValues,
  ) => {
    setLoading(true);
    try {
      setPage(1);
      const res = await searchInvestors(
        kycStatus,
        userLevel,
        showWithPendingTransactions,
        dateColumnName,
        dateValues?.startDate,
        dateValues?.endDate,
        searchItem,
        1,
        limit,
      );
      if (res.status === 200) {
        setLoading(false);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setInvestors(res.data.items);
        handleAlert('Search by date filters success', 'success');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await searchInvestors(
      kycStatus,
      userLevel,
      showWithPendingTransactions,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      1,
      selectedValue,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setInvestors(res.data.items);
    }
  };
  const handleChangePage = async (event, value) => {
    const res = await searchInvestors(
      kycStatus,
      userLevel,
      showWithPendingTransactions,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      value,
      limit,
    );
    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setInvestors(res.data.items);

      setTotalPages(res.data.meta.totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };
  useEffect(() => {
    getInvestors();
  }, []);

  useEffect(() => {
    searchForInvestors(
      kycStatus,
      userLevel,
      showWithPendingTransactions,
      dateFilter,
      dateValues,
    );
  }, [showWithPendingTransactions]);
  return (
    <>
      {loadingData ? (
        <Loader />
      ) : (
        <main className="card card-custom card-stretch gutter-b">
          <section>
            <div className="card-header">
              <div
                className="mt-10"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h1>Investors</h1>
                <p>Profile Details and documentation</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ExportButton
                    exportDocument={() =>
                      exportToExcel(
                        setExportLoading,
                        'v1/admin/investors/download',
                        {
                          ...(kycStatus && { kycStatus }),
                          ...(userLevel && { userLevel }),
                          ...(dateFilter && { dateColumnName: dateFilter }),
                          ...(dateValues?.startDate && {
                            startDate: dateValues?.startDate,
                          }),
                          ...(dateValues?.endDate && {
                            endDate: dateValues?.endDate,
                          }),
                        },
                      )
                    }
                    loading={exportLoading}
                    btnText={'Export to Excel'}
                    width="253px"
                    height="45px"
                    marginTop
                  />
                </div>
              </div>
              <div
                className="my-10 d-flex flex-wrap w-100 justify-content-between align-items-center"
                style={{
                  flexDirection: 'row',
                }}
              >
                <form
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <SelectBox
                    options={kycStatusValues}
                    name={'KYC status'}
                    setValue={e => setKycStatusValue(e)}
                  />
                  <SelectBox
                    options={UserLevelOptions1}
                    name={'User Level'}
                    setValue={e => setUserLevel(e)}
                  />

                  <button
                    type="button"
                    style={{
                      width: '200px',
                      height: '45px',
                      borderRadius: '10px',
                      padding: '20px, 148px, 20px, 135px',
                      border: '1px solid #0071CE',
                      color: '#0071CE',
                      background: '#FFFFFF',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {' '}
                    Date Filters
                  </button>

                  <div className="d-flex">
                    <input
                      type="checkbox"
                      onChange={() => {
                        setShowPendingTransactions(prev => !prev);
                      }}
                      checked={showWithPendingTransactions}
                    />
                    <p
                      style={{
                        paddingTop: '1rem',
                        marginLeft: '10px',
                      }}
                    >
                      Show only investors with pending transactions
                    </p>
                  </div>
                </form>

                <form
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by name,email , phone or reference"
                    name="plan"
                    min={0}
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',

                      width: '80%',
                      height: '45px',
                      padding: '12px 20px 12px 20px',
                      borderRadius: '10px',
                      border: '1px solid #0071CE',
                    }}
                    onChange={handleSearch}
                  />

                  <button
                    className="btn btn-primary px-6 mb-2 mr-1"
                    onClick={e => {
                      e.preventDefault();
                      setLoadingSearchResult(true);
                      searchForInvestors(
                        kycStatus,
                        userLevel,
                        showWithPendingTransactions,
                        dateFilter,
                        dateValues,
                      );
                      setLoadingSearchResult(false);
                    }}
                    style={{
                      color: 'white',
                      background: '#0071CE',
                      width: '165px',
                      border: '1px solid #0071CE',
                      borderRadius: '5px',
                    }}
                  >
                    Search
                    <span className="svg-icon svg-icon-light ml-2">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/Search.svg',
                        )}
                        alt="search icon"
                      />
                    </span>
                    {loadingSearchResult && (
                      <span className="ml-3 spinner spinner-white" />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </section>
          <section className="card-body mt-3 mb-10 pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-border table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      {investortableHeaders.map(header => (
                        <th
                          key={header.id}
                          style={{ minWidth: '100px', textAlign: 'center' }}
                          className="pl-7"
                        >
                          <span className="text-dark-75">{header.title}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {investors.map(eachInvestor => (
                      <SingleInvestor
                        key={eachInvestor.id}
                        investor={eachInvestor}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={handleChangePage}
                />
              ) : (
                ''
              )}
              {totalRecord && totalRecord > 10 ? (
                <NumberOfRows
                  handleChangeLimit={handleChangeLimit}
                  totalRecord={totalRecord}
                />
              ) : (
                ''
              )}
            </div>
          </section>
          <InvestorDate
            open={open}
            setOpen={setOpen}
            handleDateChange={handleDateChange}
            searchInvestors={searchForInvestorUsingDateRange}
            userStatus={userStatus}
            dateFilter={dateFilter}
            dateValues={dateValues}
            kycStatus={kycStatus}
            userLevel={userLevel}
            setDateFilter={setDateFilter}
          />
        </main>
      )}
    </>
  );
};

export default Investor;
