import React, { useEffect, useState } from 'react';
import { fetchUsers, searchForUsersApi } from '../_redux/KycCrud';

import SVG from 'react-inlinesvg';
import moment from 'moment';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import Pagination from '@material-ui/lab/Pagination';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { Link, useLocation } from 'react-router-dom';
import SelectBox from '../../../common/SelectBox';

import { DateInput } from '../../../common/DateInput';
import { userStatusValues } from '../../../helpers/constants';
import handleAlert from '../../../common/handleAlert';

const bcHeaders = [
  {
    id: 1,
    title: 'User ID',
  },
  {
    id: 2,
    title: 'User Email',
  },
  {
    id: 3,
    title: 'User Status ',
  },
  {
    id: 4,
    title: 'Partner ',
  },
  {
    id: 5,
    title: 'Linked Investors',
  },
  {
    id: 6,
    title: 'View Users ',
  },
];

const UsersKYC = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [users, setUsers] = useState([]);
  const [userStatus, setUserStatus] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchItem, setSearchItem] = useState('');
  const [dateValues, setDateValues] = useState({});
  const [showWithPendingTransactions, setShowPendingTransactions] = useState(
    false,
  );

  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await fetchUsers(page, limit);
      if (res.status === 200) {
        setUsers(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const searchForUsers = async (userStatus, dateFilter, dateValues) => {
    try {
      setLoadingData(true);
      setPage(1);
      const res = await searchForUsersApi(
        userStatus,
        showWithPendingTransactions,
        dateFilter,
        dateValues?.startDate,
        dateValues?.endDate,
        searchItem,
        1,
        limit,
      );

      if (res.status === 200) {
        setUsers(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoadingData(false);
      }
    } catch (error) {
      handleAlert(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const handleChangePage = async (event, value) => {
    const res = await searchForUsersApi(
      userStatus,
      showWithPendingTransactions,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      value,
      limit,
    );
    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setUsers(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await searchForUsersApi(
      userStatus,
      showWithPendingTransactions,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      1,
      selectedValue,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setUsers(res.data.items);
    }
  };

  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await searchForUsersApi(
      userStatus,
      showWithPendingTransactions,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      value.replace('+', '%2B'),
      1,
      limit,
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setUsers(res.data.items);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    searchForUsers(userStatus, dateFilter, dateValues);
  }, [showWithPendingTransactions]);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header">
          <div
            className="mt-10"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h1>Users</h1>
            <p>Profile Details and documentation</p>
          </div>
          <div
            className="my-10 d-flex flex-wrap w-100 justify-content-between align-items-center"
            style={{
              flexDirection: 'row',
            }}
          >
            <form
              className="d-flex flex-wrap"
              style={{
                marginTop: '1.5rem',
                marginBottom: '1.5rem',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <SelectBox
                options={userStatusValues}
                name={'User status'}
                setValue={e => setUserStatus(e)}
              />
              <div
                style={{ display: 'flex', gap: '40px', alignItems: 'center' }}
              >
                <DateInput
                  name="startDate"
                  label="Start Date"
                  handleDateChange={handleDateChange}
                  max={moment().format('YYYY-MM-DD')}
                  value={dateValues?.startDate}
                  marginTop="mt-0"
                />
                <DateInput
                  name="endDate"
                  label="End Date"
                  handleDateChange={handleDateChange}
                  max={moment().format('YYYY-MM-DD')}
                  value={dateValues?.endDate}
                  marginTop="mt-0"
                />
              </div>

              <div className="d-flex">
                <input
                  type="checkbox"
                  onChange={e =>
                    setShowPendingTransactions(!showWithPendingTransactions)
                  }
                  checked={showWithPendingTransactions}
                />
                <p
                  style={{
                    paddingTop: '1rem',
                    marginLeft: '10px',
                  }}
                >
                  Show only users with pending transactions
                </p>
              </div>
            </form>

            <form
              className="d-flex flex-wrap"
              style={{
                marginTop: '1.5rem',
                marginBottom: '1.5rem',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <input
                className="form-control"
                type="text"
                placeholder="Search by name,email or phone"
                name="plan"
                min={0}
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',

                  width: '80%',
                  height: '45px',
                  padding: '12px 20px 12px 20px',
                  borderRadius: '10px',
                  border: '1px solid #0071CE',
                }}
                onChange={handleSearch}
              />

              <button
                className="btn btn-primary px-6 mb-2 mr-1"
                onClick={e => {
                  e.preventDefault();
                  searchForUsers(userStatus, dateFilter, dateValues);
                }}
                style={{
                  color: 'white',
                  background: '#0071CE',
                  width: '165px',
                  border: '1px solid #0071CE',
                  borderRadius: '5px',
                }}
              >
                Search
                <span className="svg-icon svg-icon-light ml-2">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')}
                    alt="search icon"
                  />
                </span>
                {loadingData && <span className="ml-3 spinner spinner-white" />}
              </button>
            </form>
          </div>
        </div>
        <div className="card-body mt-3 mb-10 pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              {/* table table-head-custom table-head-bg table-border table-vertical-center */}
              <table className="table table-head-bg table-head-custom table-head-bg  table-border table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    {bcHeaders.map(header => (
                      <th
                        key={header.id}
                        style={{ minWidth: '100px', textAlign: 'center' }}
                        className="pl-7"
                      >
                        <span className="text-dark-75">{header.title}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {users.map(eachUser => (
                    <tr
                      style={{
                        textAlign: 'center',
                      }}
                      key={eachUser.id}
                    >
                      <td className="pl-0 py-8">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachUser.id}
                        </div>
                      </td>
                      <td className="pl-0 py-8">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachUser?.email && eachUser?.email
                            ? eachUser?.email
                            : '-'}
                        </div>
                      </td>
                      <td className="pl-0 py-8">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachUser?.status && eachUser?.status
                            ? eachUser?.status
                            : '---'}
                        </div>
                      </td>
                      <td className="pl-0 py-8">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachUser?.partner && eachUser?.partner
                            ? eachUser?.partner?.name
                            : '---'}
                        </div>
                      </td>
                      <td className="pl-0 py-8">
                        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                          {eachUser?.linkedInvestors &&
                          eachUser?.linkedInvestors
                            ? eachUser?.linkedInvestors
                            : '---'}
                        </div>
                      </td>

                      <td className="pr-0">
                        <Link
                          to={{
                            pathname: `/users/${eachUser.id}`,
                            state: {
                              prevUrl: location.pathname,
                            },
                          }}
                          className="btn btn-light-primary  font-size-sm"
                        >
                          View User
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
            {totalPages && totalPages > 0 ? (
              <Pagination
                page={page}
                count={totalPages}
                onChange={handleChangePage}
              />
            ) : (
              ''
            )}
            {totalRecord && totalRecord > 10 ? (
              <NumberOfRows
                handleChangeLimit={handleChangeLimit}
                totalRecord={totalRecord}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersKYC;
